$(document).ready ->
	watcher =  scrollMonitor.create $('.navbar-header'),{top:100,bottom:100}
	watcher.enterViewport ->
		$(@.watchItem).find('.brand').removeClass 'rotated'
	watcher.exitViewport ->
		$(@.watchItem).find('.brand').addClass 'rotated'
	elevator = new Elevator
		mainAudio: '/audio/elevator.mp3'
		endAudio: '/audio/ding.mp3'
		duration:2500
	$('.back-to-top').on 'click',->
		# $(document).scrollTo 0,500
		try
			elevator.elevate()
		catch e
			console.log e
		return false;