$(document).ready ->
    $('.block-banner.rotator').each ->
        $block = $(@)
        options =
            prevArrow:'<button type="button" data-role="none" class="carousel-prev slick-prev"><i class="fa fa-angle-left"></i></button>'
            nextArrow:'<button type="button" data-role="none" class="carousel-next slick-next"><i class="fa fa-angle-right"></i></button>'
            dots:!$block.hasClass('no-dots')
            autoplaySpeed:4000
            autoplay:true
        slock = $block.slick options
        $block.find('.item.hidden').removeClass('hidden')
