$(document).ready ->
	conf =
		key: '3d39c73ea34d0288e1cbd0df3f95cbef'
		# key: 'bb8a81fa65f1262062ccba30f11502fd'
		source:
			mpd: '//bitdash-a.akamaihd.net/content/sintel/sintel.mpd'
			hls: 'https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8'
			poster: 'https://bitdash-a.akamaihd.net/content/sintel/sintel-poster.png'
	player = bitdash('bitmovinPlayer').setup conf

	setTimeout ->
		$('.bitdash-html5-player-video-container').css
			width: 'auto'
			height: 'auto'
	, 50

	# Server
	polygonConf =
		# url:'http://test-eu1-cluster.polygoncast.com',
		# url:'http://polygonsocket-1.polygo-test-backend-2.molszanski.cont.tutum.io',
		url: 'https://router-dev.polygoncast.com'
		port: '443'

	polygoncast.connect polygonConf
	polygoncast.defineLobby '//bitdash-a.akamaihd.net/content/sintel/sintel.mpd'

	# Autointegration
	polygoncast.activateVideoFinder()
	polygoncast.poligoncastify()

	# Stats
	if polygonSubscribeStats? then polygonSubscribeStats()
