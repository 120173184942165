'use strict'

### Controllers ###

angular.module('app.controllers', [])

.controller('AppCtrl', [
  '$scope'
  '$resource'
  '$rootScope'

($scope, $resource, $rootScope) ->
	$scope.a = 'Baba'
])

