$(document).ready ->
	$('.block-demo').each ->
		# url = "http://demo.unified-streaming.com/video/caminandes/caminandes.ism/caminandes.mpd",
		# url = "http://dash.edgesuite.net/dash264/TestCases/1a/netflix/exMPD_BIP_TC1.mpd",
		# url = "http://dash.edgesuite.net/dash264/TestCases/2a/qualcomm/1/MultiResMPEG2.mpd",


		# PolygonCast Bootstrapping :: Setup config and connect to server
		polygonConf = server:
			url: 'https://router-dev.polygoncast.com'
			port: '443'
		_transport.connect polygonConf.server

		# Dash.js Bootstrapping :: Setup config and connect to server
		context = new Dash.di.DashContext()
		dashPlayer = new MediaPlayer(context)
		dashPlayer.startup()
		dashPlayer.attachView document.querySelector '.dash-video-player'
		dashPlayer.setAutoPlay true

		firstVideo = true

		playVideo = (manifestUrl) ->
			manifestUrl = manifestUrl.trim()
			console.debug "Setting up #{manifestUrl}"

			if firstVideo
				_transport.defineLobby manifestUrl
				firstVideo = false
			else
				_transport.clearQueue()
				_transport.unsubscribeAll()

			dashPlayer.attachSource manifestUrl, null, null
			# else
			# 	_transport.clearQueue()


			# 	context2 = new (Dash.di.DashContext)
			# 	dashPlayer2 = new MediaPlayer(context)

			# 	dashPlayer2.startup()
			# 	dashPlayer2.setAutoPlay true
			# 	dashPlayer2.attachView document.querySelector '#newPlayer'
			# 	dashPlayer2.attachSource manifestUrl



		# Play button
		$(@).find('.start-demo,.dash-video-player').on 'click',->
			playVideo $('#manifestUrl').val()

		# Change input URL based on list
		$('#manifestList li a').on 'click', (e) ->
			e.preventDefault()
			$('input.form-control.video-source').val @href
